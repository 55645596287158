import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { Media } from './types'
import { ClearRequestPayload, addOrReplaceItemInListById } from '_core/store'

export interface MediasState extends ApiStoreState {
  all: Media[]
  currentMediaId?: string
}
export type MediasApi = StoreActionApi<MediasState>

const initialState: MediasState = {
  all: [],
  currentMediaId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ setState, getState }: MediasApi) => {
    const res = await callApiFromStore({
      url: '/medias',
    }, setState, getState)
    if (res && res.result)
      setState({ all: res.result })
  },
  get: (_id: string) => async ({ setState, getState }: MediasApi) => {
    const res = await callApiFromStore({
      url: `/medias/${_id}`
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result),
      currentMediaId: res.result._id
    })
  },
  create: (data: File[]) => async ({ setState, getState }: MediasApi) => {
    const formData = new FormData()
    data.forEach(file => formData.append('files', file))
    const res = await callApiFromStore({
      url: '/medias',
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      },
      progress: true
    }, setState, getState)

    res && res.forEach((media: any) => {
      if (media._id)
        setState({
          all: [...getState().all, media]
        })
    })
  },
  update: (data: Media) => async ({ setState, getState }: MediasApi) => {
    const res = await callApiFromStore({
      url: `/medias/${data._id}`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  delete: (_id: string) => async ({ setState, getState }: MediasApi) => {
    const res = await callApiFromStore({
      url: `/medias/${_id}`,
      method: 'delete'
    }, setState, getState)
    if (res && res.result)
      setState({ 
        all: [...getState().all.filter(m => m._id !== _id)]
      })
  },
  setCurrent: (media?: Media) => ({ setState }: MediasApi) => {
    setState({ currentMediaId: media && media._id })
  },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: MediasApi) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: MediasApi) => {
    setState({ requests: initialState.requests })
  },
}

export type MediasActions = typeof actions

const Store = createStore<MediasState, MediasActions>({
  name: 'medias',
  initialState,
  actions
})

export const MediasSubscriber = createSubscriber(Store)
export const useMedias = createHook(Store)
export const useCurrentMedia = createHook(Store, {
  selector: (state: MediasState) => state.currentMediaId &&
    state.all.find(media => media._id === state.currentMediaId)
})
export const MediasContainer = createContainer(Store)

export default Store