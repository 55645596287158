import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { LoginActionPayload, IsAuthPayload, UpdateProfilePayload } from './types'
import { getStoreState } from '_core/local-storage'

export interface SessionState extends ApiStoreState {
  logged: boolean
  token?: string
  user?: any
}
export type SessionApi = StoreActionApi<SessionState>

const initialState: SessionState = {
  logged: false,
  token: undefined,
  user: undefined,
  requests: {}
}

export const actions = {
  login: (data: LoginActionPayload) => async ({ setState, getState }: SessionApi): Promise<void> => {
    const res = await callApiFromStore({
      url: '/auth',
      method: 'post',
      data
    }, setState, getState)
    if (res && res.result && res.result.token) {
      setState({ 
        logged: true,
        token: res.result.token,
        user: res.result.user
      })
    }
  },
  updateProfile: (data: UpdateProfilePayload) => async ({ setState, getState }: SessionApi): Promise<void> => {
    const res = await callApiFromStore({
      url: `/users/${getState().user._id}`,
      method: 'put',
      data
    }, setState, getState)
    if (res && res.result) {
      setState({
        user: res.result
      })
    }
  },
  logout: () => async ({ setState }: SessionApi): Promise<void> => {
    setState(initialState)
  },
  isAuth: (data: IsAuthPayload) => async ({ setState, getState }: SessionApi): Promise<void> => {
    if (!data.token)
      data.token = getState().token
    const res = await callApiFromStore({
      url: '/is-auth',
      data
    }, setState, getState)
    if (res && res.result && res.result.token) {
      setState({ 
        logged: true,
        token: res.result.token,
        user: res.result.user
      })
    } else {
      setState(initialState)
    }
  }
}

export type SessionActions = typeof actions

const name = 'session'

const Store = createStore<SessionState, SessionActions>({
  name,
  initialState: getStoreState(name) || initialState,
  actions
})

export const SessionSubscriber = createSubscriber(Store)
export const useSession = createHook(Store)
export const SessionContainer = createContainer(Store)
export const useAppUser = createHook(Store, {
  selector: (state) => state.user
})

export default Store