import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'

export interface CheckOptions {
  value?: string
  name?: string
  label: string
  info?: string
  disabed?: boolean
  required?: boolean
  inline?: boolean
  [key: string]: any
}

export interface CheckFieldProps extends BaseFormFieldProps {
  options: CheckOptions[]
  inline?: boolean
  type?: 'checkbox' | 'radio'
  defaultValue?: boolean | string[]
  select?: boolean
}

export const CheckboxField = (props: CheckFieldProps) => {
  const [values, setValues] = React.useState(props.defaultValue)
  return (
    <Field { ...props }>
      <div>
        {
          props.options.map((option, index: number) => 
            <Form.Check
              key={ index }
              type={ props.type || 'checkbox' }
              inline={ props.inline || option.inline }
              { ...option }
              id={ String(option.value) }
            >
              <Form.Check.Input
                type={ props.type || 'checkbox' }
                isValid={ props.isValid }
                isInvalid={ Boolean(props.error) }
                name={ option.name || props.name }
                defaultChecked={ props.select ?
                  props.defaultValue && Array.isArray(props.defaultValue) && option.value && props.defaultValue.includes(option.value)
                  : props.defaultValue
                }
                value={ option.value }
                onChange={ (e) => {
                  if (props.select) {
                    let newValues: string[] = []
                    if (e.target.checked && !values.includes(e.target.value))
                      newValues = [...values, e.target.value]
                    else if (!e.target.checked && values.includes(e.target.value))
                      newValues = [...values.filter(v => v !== e.target.value)]
                    props.onChange && props.onChange(newValues)
                    setValues(newValues)
                  } else 
                    props.onChange && props.onChange(e.target.checked)
                } }
              />
              <Form.Check.Label>
                { option.label }
              </Form.Check.Label>
              {
                option.info &&
                <Form.Control.Feedback>{ option.info }</Form.Control.Feedback>
              }
            </Form.Check>
          )
        }
      </div>
    </Field>
  )
}

export default CheckboxField
