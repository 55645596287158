import * as React from 'react'
import { useMedias } from '_stores/medias/store'
import Title from '_components/title'
import PageApp from '_containers/app-pages/page-app'
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faCheckSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import Medias from '_components/medias'


const MediasList: React.FunctionComponent = () => {
  const [state, actions] = useMedias()
  React.useEffect(() => {
    actions.fetch()
  }, [])

  //Select mode
  const [areSelectable, setSelectable] = React.useState(false)
  const [selectedMedias, setSelectedMedias] = React.useState([] as string[])
  const selectMedia = (mediaId: string) => {
    const indexOfMedia = selectedMedias.findIndex(m => m === mediaId)
    if (indexOfMedia === -1)
      return setSelectedMedias([...selectedMedias, mediaId])
    selectedMedias.splice(indexOfMedia, 1)
    setSelectedMedias([...selectedMedias])
  }
  
  return (
    <PageApp>
      <Title
        label='Medias'
      />
      <ButtonGroup>
        <Button
          variant={ areSelectable ? 'primary' : 'secondary' }
          onClick={ () => setSelectable(!areSelectable) }
        >
          <FontAwesomeIcon icon={ faCheckSquare } />
        </Button>
        {
          areSelectable &&
          <DropdownButton
            as={ ButtonGroup }
            title={ `${selectedMedias.length} medias selected` }
            id='bg-nested-dropdown'
          >
            <Dropdown.Item
              eventKey='1'
              onClick={ () => {
                selectedMedias.forEach(mediaId => 
                  actions.delete(mediaId)  
                ) 
                setSelectedMedias([])
              } }
            >
              <FontAwesomeIcon icon={ faTrash } className='mr-2' />
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              eventKey='2'
              onClick={ () => {
                if (state.all.length !== selectedMedias.length)
                  setSelectedMedias(state.all.map(m => m._id) as string[])
                else
                  setSelectedMedias([])
              } }
            >
              <FontAwesomeIcon icon={ faCheckDouble } className='mr-2' />
              { state.all.length !== selectedMedias.length ?
                'Select all' : 'Unselect all'
              }
            </Dropdown.Item>
          </DropdownButton>
        }
      </ButtonGroup>

      <Medias
        medias={ state.all }
        onDrop={ actions.create }
        onDropzoneHide= { () => actions.clearRequest({ key: 'post /medias' }) }
        request={ state.requests['post /medias'] }
        selectMode={ areSelectable }
        onSelect={ (media) => media._id && selectMedia(media._id) }
        selected={ selectedMedias }
      />
    </PageApp>
  )
}

export default MediasList