import * as React from 'react'
import { useParams } from 'react-router-dom'
import { defaults } from 'react-sweet-state'
import { saveStoreState } from './local-storage'

export interface ClearRequestPayload {
  key: string
}

export const useResourceFromParams = (actions: any, paramsKey = 'id'): void => {
  const locationParams: any = useParams()
  React.useEffect(() => {
    if (locationParams[paramsKey] && locationParams[paramsKey] !== 'new')
      actions.get(locationParams[paramsKey])
    else
      actions.setCurrent()
    return () => actions.setCurrent()
  }, [locationParams.id]) 
}

export const addOrReplaceItemInListById = (list: any[], item: any): any[] => {
  const existingIndex = item._id && list.findIndex(doc =>
    doc._id === item._id
  )
  if (existingIndex >= 0)
    list[existingIndex] = item
  else
    list.push(item)
  return [...list]
}

const persist = (storeState: any) => (next: any) => (arg: any) => {
  if (storeState.key.match('session')) {
    const toSave = { ...storeState.getState(), ...arg }
    delete toSave.requests
    saveStoreState('session', toSave)
  }
  return next(arg)
}

defaults.middlewares.add(persist)