import * as React from 'react'
import Form from '_components/form'
import { usePages } from '_stores/pages/store'

export interface PageFormProps {
  page?: any
  onSubmit(values: any): void
}

const PageForm:React.FunctionComponent<PageFormProps> = ({ page = {}, onSubmit }) => {
  const [state] = usePages()
  return (
    <>
      <Form
        fields={ [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Name...',
            label: 'Name'
          },
          {
            name: 'settings.previewUrl',
            type: 'text',
            label: 'Preview URL'
          },
          {
            name: 'settings.locales',
            type: 'texts',
            label: 'Locales',
            addFieldButtonLabel: 'Add locale'
          },
          {
            name: 'settings.contextResources',
            type: 'checkbox',
            select: true,
            label: 'Context data resources',
            options: [
              { 
                value: 'resources',
                label: 'Resources'
              },
              {
                value: 'medias',
                label: 'Medias'
              }
            ]
          }
        ] }
        initialValues={ page }
        validate={ (values: any) => {
          const errors: any = {}
          if (!values.name)
            errors.name = 'Required field'
          return errors
        } }
        onSubmit={ onSubmit }
        request={ state.requests[page ? `put /pages/${page._id}` : 'post /pages'] }
        submitLabel='Save'
      />
    </>

  )
}
export default PageForm