import * as React from 'react'
import Title from '_components/title'
import { useCurrentMedia } from '_stores/medias/store'
import MediaForm from './form'
import { Media } from '_stores/medias/types'
import { useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'

export interface MediaItemProps {
  media: Media
}

const MediaItem: React.FunctionComponent = () => {
  const [currentMedia, actions] = useCurrentMedia()
  const history = useHistory()

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentMedia }
        actions={ actions }
      >
        <Title
          label={ currentMedia ? currentMedia.name : 'New media' }
          button={ {
            label: 'Back to list',
            onClick: () => history.push('/medias')
          } }
        />
        <MediaForm 
          onSubmit={ currentMedia ? actions.update : actions.create }
          media={ currentMedia }
        />
      </ApiItemWrapper>
    </PageApp>
  )
}

export default MediaItem