import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faUserFriends, faUserCircle, faSignOutAlt, faIdCard, faPager, faImages, faPlusCircle, faKey } from '@fortawesome/free-solid-svg-icons'
import { ProSidebar, Menu, SidebarContent, SidebarHeader, SubMenu } from 'react-pro-sidebar'
import SidebarLink from './link'
import { useAppUser } from '_stores/session/store'
import SideBarPages from './pages'

interface SideBarProps {
  collapsed?: boolean
  toggled?: boolean
  setToggled(toggled: boolean): void
}

const Sidebar: React.FunctionComponent<SideBarProps> = ({
  collapsed, 
  toggled,
  setToggled
}) => {
  const [appUser] = useAppUser()
  
  return (
    <ProSidebar
      className='sidebar'
      breakPoint='md'
      collapsed={ collapsed }
      toggled={ toggled }
    >
      <SidebarHeader>
        <div className='sidebar_header'>
        Codefathers
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          <SubMenu
            title={ `${appUser.firstName} ${appUser.lastName}` }
            icon={ <FontAwesomeIcon icon={ faUserCircle } /> }
          >
            <SidebarLink
              to='/me'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faIdCard } /> }
              label={ <span>My profile</span> }
            />
            <SidebarLink
              to='/logout'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faSignOutAlt } /> }
              label={ <span>Logout</span> }
            />
          </SubMenu>
        </Menu>
        <Menu>
          <SideBarPages
            setToggled={ setToggled }
          />
          <SidebarLink
            to='/medias'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faImages } /> }
            label={ <span>Medias</span> }
          />
          <SidebarLink
            to='/resources'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faFolder } /> }
            label={ <span>Resources</span> }
          />
          <SidebarLink
            to='/users'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faUserFriends } /> }
            label={ <span>Users</span> }
          />
          <SidebarLink
            to='/api-keys'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faKey } /> }
            label={ <span>API Keys</span> }
          />
        </Menu>
      </SidebarContent>
    </ProSidebar>
  ) }

export default Sidebar