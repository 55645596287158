import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import { Button, Modal } from 'react-bootstrap'
import Medias from '_components/medias'
import { useMedias } from '_stores/medias/store'
import { Media } from '_stores/medias/types'

export interface MediasFieldProps extends BaseFormFieldProps {
  multiple?: boolean
}

const MediasField: React.FunctionComponent<MediasFieldProps> = (props) => {
  const [state, actions] = useMedias()

  //Modal display
  const [modalIsVisible, setModalVisible] = React.useState(false)
  //Fetch medias
  /*React.useEffect(() => {
    actions.fetch()
  }, [])*/

  //Manage used medias from default value
  const [usedMedias, setUsedMedias] = React.useState([] as Media[])
  React.useEffect(() => {
    if (props.defaultValue && props.defaultValue[0])
      setUsedMedias(state.all.filter(media => props.defaultValue.includes(media._id)))
  }, [props.defaultValue, state.all])

  //Selected medias
  const [selectedMedias, setSelectedMedias] = React.useState(props.defaultValue || [] as string[])
  const selectMedia = (mediaId: string) => {
    const indexOfMedia = selectedMedias.findIndex((m: string) => m === mediaId)
    if (indexOfMedia === -1) {
      if (props.multiple)
        return setSelectedMedias([...selectedMedias, mediaId])
      return setSelectedMedias([mediaId])
    }
    if (props.multiple) {
      selectedMedias.splice(indexOfMedia, 1)
      setSelectedMedias([...selectedMedias])
    } else {
      setSelectedMedias([])
    }
  }
  
  return (
    <>
      <Modal
        show={ modalIsVisible }
        onHide={ () => setModalVisible(false) }
        size='xl'
      >
        <Modal.Header>
          Medias
        </Modal.Header>
        <Modal.Body>
          <Medias 
            medias={ state.all }
            onDrop={ (files) => actions.create(files) }
            onDropzoneHide= { () => actions.clearRequest({ key: 'post /medias' }) }
            request={ state.requests['post /medias'] }
            selectMode
            onSelect={ (media) => {
              media._id && selectMedia(media._id)
            } }
            selected={ selectedMedias }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='default'
            onClick={ () => setModalVisible(false) }
          >
            Cancel
          </Button>
          <Button 
            onClick={ () => {
              props.form && props.form.setFieldValue(props.field.name, selectedMedias)
              if (props.onEnterPress)
                props.onEnterPress(selectedMedias)
              setModalVisible(false)
            } }
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
      <Field { ...props }>
        <Button onClick={ () => setModalVisible(true) }>
          Select medias
        </Button>
      </Field>
      <Medias
        medias={ usedMedias }
        readOnly
      />
    </>
  )
}

export default MediasField