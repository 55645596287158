import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}


export interface SelectFieldProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
}

export const SelectField: React.FunctionComponent<SelectFieldProps> = (props) => {
  const options = React.useMemo(() => {
    if (props.placeholder)
      return [{ value: '__none', label: props.placeholder }, ...props.options]
    return props.options
  }, [props.placeholder, props.options])
  return (
    <Field { ...props }>
      <Form.Control
        placeholder={ props.placeholder }
        isValid={ !props.hideValid && !Boolean(props.error) }
        isInvalid={ Boolean(props.error) }
        onChange={ (e) => props.onChange &&
          props.onChange(e.target.value !== '__none' ? e.target.value : undefined)
        }
        onBlur={ (e: any) => props.onBlur &&
          props.onBlur(e.target.value !== '__none' ? e.target.value : undefined) }
        as='select'
        multiple={ props.multiple }
        defaultValue={ props.defaultValue }
      >
        {
          options.map((option, index: number) =>
            <option 
              key={ option.value || index }
              value={ option.value }
            >
              { option.label }
            </option>
          )
        }
      </Form.Control>
    </Field>
  )
}


export default SelectField