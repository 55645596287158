import * as React from 'react'
import Form from '_components/form'

export interface ApiKeyFormProps {
  apiKey?: any
  onSubmit(values: any): void
}

const ApiKeyForm:React.FunctionComponent<ApiKeyFormProps> = ({ apiKey = {}, onSubmit }) => (
  <>
    <Form
      fields={ [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Name...'
        },
        {
          name: 'role',
          type: 'select',
          options: [
            {
              label: 'Read',
              value: 'read'
            },
            {
              label: 'Write',
              value: 'write'
            }
          ]
        }
      ] }
      initialValues={ apiKey }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.name)
          errors.name = 'Required field'
        return errors
      } }
      onSubmit={ onSubmit }
      submitLabel='Save'
    />
  </>

)

export default ApiKeyForm