import * as React from 'react'
import Form from '_components/form'
import { useUsers } from '_stores/users/store'
import { PasswordRecoveryPayload } from '_stores/users/types'

const LoginForm: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  return (
    <Form
      fields={ [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email...'
        }
      ] }
      initialValues={ {} }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.email)
          errors.email = 'Required field'
        return errors
      } }
      onSubmit={ (values: PasswordRecoveryPayload) => actions.passwordRecovery(values) }
      request={ state.requests['post /users/password-recovery'] }
      submitLabel='Send me an email'
      submitLg
    />
  )
}

export default LoginForm