import * as React from 'react'
import Form from '_components/form'
import { LoginActionPayload } from '_stores/session/types'

export interface LoginFormProps {
  onSubmit(values: LoginActionPayload): void
}

const LoginForm: React.FunctionComponent<LoginFormProps> = (props) =>
  <Form
    fields={ [
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email...'
      },
      {
        name: 'password',
        type: 'password',
        placeholder: 'Password...'
      }/*,
      {
        name: 'remember',
        type: 'checkbox',
        options: [
          {
            label: 'Remember me',
            value: 'true'
          }
        ]
      }*/
    ] }
    initialValues={ {} }
    validate={ (values: any) => {
      const errors: any = {}
      if (!values.email)
        errors.email = 'Required field'
      if (!values.password)
        errors.password = 'Required field'
      return errors
    } }
    onSubmit={ props.onSubmit }
    submitLabel='Login'
    submitLg
  />

export default LoginForm