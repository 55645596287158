import * as React from 'react'
import Form from '_components/form'

export interface MediaFormProps {
  media?: any
  onSubmit(values: any): void
}

const MediaForm:React.FunctionComponent<MediaFormProps> = ({ media = {}, onSubmit }) => (
  <>
    <Form
      fields={ [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Name...'
        }
      ] }
      initialValues={ media }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.name)
          errors.name = 'Required field'
        return errors
      } }
      onSubmit={ onSubmit }
      submitLabel='Save'
    />
  </>

)

export default MediaForm