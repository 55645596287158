import * as React from 'react'
import { Formik, FormikProps, Field, Form as FormikForm } from 'formik'
import RequestMessage from '_components/request-message'
import { Button } from 'react-bootstrap'
import { ApiStoreRequest } from '_core/api'
import { FormFieldProp } from './types'
import { getComponent } from './utils'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

export type FieldsProps = FormFieldProp[]

export interface FormProps {
  fields: FieldsProps
  initialValues?: any
  request?: ApiStoreRequest
  validate?(value: any): any
  onSubmit(values: any): any
  onFieldChange?(fieldName: string, value: any): void
  success?(): any
  submitLabel?: string
  submitLg?: boolean
  cancelButton?: {
    onClick(): void
    label?: string
  }
}

const Form: React.FunctionComponent<FormProps> = ({ 
  fields, 
  initialValues, 
  validate, 
  onSubmit, 
  request, 
  success,
  submitLabel,
  submitLg,
  cancelButton,
  onFieldChange
}) => {
  if (request && request.status === 'success' && success)
    return success()
  return (
    <Formik
      enableReinitialize
      initialValues={ initialValues || {} }
      validate={ validate }
      onSubmit={ onSubmit }
      on
    >
      {({
        values = {},
        errors = {},
        touched = {},
        submitCount,
        setFieldValue
      }: FormikProps<any>) => (
        <FormikForm>

          <RequestMessage
            request={ request }
          />
          {
            fields.map(field =>
              field.type === 'form' ?
                <div key={ field.name }>
                  <field.form />
                </div>
                :
                <Field
                  key={ field.name }
                  { ...field }
                  component={ getComponent(field.type) }
                  placeholder={ field.placeholder }
                  submitCount={ submitCount }
                  error={ errors[field.name] }
                  defaultValue={ _.get(values, field.name) }
                  onChange={ (value: any) => {
                    onFieldChange && onFieldChange(field.name, value)
                    setFieldValue(field.name, value)
                  } }
                  touched={ touched[field.name] }
                />
            )
          }
          <Button
            className='mt-4'
            variant='primary'
            block={ submitLg }
            type='submit'
          >
            { submitLabel || 'Submit' }
            {
              request && request.status === 'inProgress' && <FontAwesomeIcon className='ml-1' icon={ faSpinner } spin/>
            }
            {
              request && request.status === 'success' && <FontAwesomeIcon className='ml-1' icon={ faCheck } />
            }
          </Button>
          {
            cancelButton &&
            <Button
              className='mt-4 ml-2'
              variant='default'
              block={ submitLg }
              type='button'
              onClick={ cancelButton.onClick }
            >
              { cancelButton.label || 'Cancel' }
            </Button>

          }
        </FormikForm>
      )}
    </Formik>
  )
}

export default Form
