import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPassport } from '@fortawesome/free-solid-svg-icons'
import LoginForm from './form'
import { useSession } from '_stores/session/store'
import { Link } from 'react-router-dom'

const Login: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  return (
    <PageCenteredForm 
      title={ 'Login' }
      titleDescription={ 'Login here' }
      icon={ <FontAwesomeIcon icon={ faPassport } size='2x' /> }
      footer={ <Link to='/password-recovery'>Forgot your password?</Link> }
    >
      <LoginForm 
        onSubmit={ (values) => actions.login(values) }
      />
    </PageCenteredForm>
  )
}

export default Login