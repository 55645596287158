import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import ConfirmationModal from '_components/confirmation-modal'
import Form, { FieldsProps } from '_components/form'
import { useCurrentPage } from '_stores/pages/store'
import { PageField } from '_stores/pages/types'

interface PageFieldFormProps {
  field?: PageField
  defaultValue?: {
    parentId?: string
  }
  onSubmit?(values: any): void
  onRemoved?(): void
  onCreated?(): void
}

const PageFieldForm: React.FunctionComponent<PageFieldFormProps> = ({
  field,
  onSubmit,
  onRemoved,
  onCreated,
  defaultValue,
}) => {
  const [fieldType, setFieldType] = React.useState(field ? field.type : 'text')
  const [fieldName, setFieldName] = React.useState(field ? field.name : '')

  const commonFields: FieldsProps = [
    {
      name: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      placeholder: 'Choose...',
      options: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Wysiwyg',
          value: 'wysiwyg'
        },
        {
          label: 'Medias',
          value: 'medias'
        },
        {
          label: 'Select',
          value: 'select'
        },
        {
          label: 'List',
          value: 'list'
        }
      ]
    },
    {
      name: 'required',
      type: 'checkbox',
      options: [
        {
          label: 'Required',
          value: true 
        }
      ]
    }
  ]

  const mediasFields: FieldsProps = [
    ...commonFields,
    {
      name: 'options.accept',
      type: 'text',
      label: 'Accepted'
    },
    {
      name: 'options.multiple',
      type: 'checkbox',
      options: [
        {
          label: 'Multiple',
          value: true 
        }
      ]
    }
  ]

  const wysiwygFields: FieldsProps = [
    ...commonFields,
    {
      name: 'options.headings',
      type: 'checkbox',
      options: [
        {
          label: 'Headings',
          value: true 
        }
      ]
    },
    {
      name: 'options.bold',
      type: 'checkbox',
      options: [
        {
          label: 'Bold',
          value: true 
        }
      ]
    },
    {
      name: 'options.italic',
      type: 'checkbox',
      options: [
        {
          label: 'Italic',
          value: true 
        }
      ]
    },
    {
      name: 'options.underline',
      type: 'checkbox',
      options: [
        {
          label: 'Underline',
          value: true
        }
      ]
    },
    {
      name: 'options.link',
      type: 'checkbox',
      options: [
        {
          label: 'Link',
          value: true
        }
      ]
    }
  ]

  const listFields: FieldsProps = [
    ...commonFields,
    {
      name: 'options.maxItems',
      label: 'Max items',
      type: 'number'
    },
    {
      name: 'options.identifierKey',
      label: 'Identifier key',
      type: 'text'
    }
  ]
  
  const formFields: FieldsProps = React.useMemo(() => {
    if (fieldType === 'wysiwyg')
      return wysiwygFields
    if (fieldType === 'medias')
      return mediasFields
    if (fieldType === 'list')
      return listFields
    return commonFields
  }, [fieldType])

  const [currentPage, actions] = useCurrentPage()
  const [deletePromptIsOpen, displayDeletePrompt] = React.useState(false)
  return (
    <>
      <Form
        fields={ formFields }
        initialValues={ field || { type: fieldType || 'text', name: fieldName } }
        validate={ (values: any) => {
          const errors: any = {}
          if (!values.name)
            errors.name = 'Required field'
          if (!values.type)
            errors.type = 'Required field'
          return errors
        } }
        onSubmit={ (values) => {
          if (onSubmit)
            return onSubmit(values)
          if (!currentPage || !currentPage._id)
            return 
          if (field && field._id)
            actions.updateField(currentPage._id, values)
          else {
            actions.createField(currentPage._id, values, defaultValue && defaultValue.parentId)
            onCreated && onCreated()
          }
        }
        }
        onFieldChange={ (fieldName, value) => {
          if (fieldName === 'type')
            setFieldType(value) 
          if (fieldName === 'name')
            setFieldName(value)
        } }
        submitLabel='Save'
        cancelButton={ {
          label: 'Delete',
          onClick: () => {
            displayDeletePrompt(true)
          }
        } }
      />
      <ConfirmationModal
        isOpen={ deletePromptIsOpen }
        confirm={ () => {

          if (currentPage && currentPage._id && field && field._id) {
            actions.removeField(currentPage._id, field._id)
          }
          if (onRemoved)
            onRemoved()
          displayDeletePrompt(false)
        } }
        cancel={ () => displayDeletePrompt(false) }
        title='Remove user access'
        description={ 
          <p>
            { field && <span>Are you sure you want to delete <strong>{ field.name }</strong> ?</span> }
          </p>
        }
      />
    </>
  )
}
export default PageFieldForm