import * as React from 'react'
import Title from '_components/title'
import { useUsers, useCurrentUser } from '_stores/users/store'
import { useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBan } from '@fortawesome/free-solid-svg-icons'
import ConfirmationModal from '_components/confirmation-modal'
import RequestMessage from '_components/request-message'
import AutoSaveField from '_components/form/auto-save-field'
import { useAppUser } from '_stores/session/store'
import DescriptionList from '_components/description-list'
import ApiItemWrapper from '_containers/api-item-wrapper'

const UserItem: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  const [currentUser] = useCurrentUser()
  const [appUser] = useAppUser()
  const history = useHistory()

  //Disabled confirmation modal
  const [promptIsOpen, displayPrompt] = React.useState(false)

  //Delete request
  const deleteRequestKey = currentUser && `delete /users/${currentUser._id}`
  const deleteRequest = deleteRequestKey && state.requests[deleteRequestKey]
  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentUser }
        actions={ actions }
      >
        <Title
          label={ currentUser ? 
            <span>
              { currentUser.disabled && <FontAwesomeIcon icon={ faBan } className='mr-1'/> }
              { currentUser.firstName } { currentUser.lastName }
            </span>
            : 'Invite a user'
          }
          button={ {
            label: <FontAwesomeIcon icon={ faArrowLeft } />,
            onClick: () => history.push('/users'),
            variant: 'default'
          } }
        />
        {
          currentUser &&
        <div>
          <DescriptionList
            items={ [
              {
                label: 'Name',
                value: `${ currentUser.firstName } ${ currentUser.lastName }`
              },
              {
                label: 'Email',
                value: currentUser.email
              },
              {
                label: 'Created at',
                value: moment(currentUser.dates.created).format('DD/MM/YYYY')
              },
              {
                label: 'Role',
                value: appUser._id === currentUser._id ?
                  currentUser.role
                  :
                  <AutoSaveField 
                    field={ {
                      name: 'role',
                      type: 'select',
                      options: [
                        {
                          value: 'user',
                          label: 'User'
                        },
                        {
                          value: 'admin',
                          label: 'Admin'
                        }
                      ]
                    } }
                    onSubmit={ (value) => actions.update({
                      id: currentUser && currentUser._id,
                      role: value
                    }) }
                    initialValue={ currentUser.role }
                  />
              },
              {
                label: 'Active',
                value: currentUser.disabled ? 'No' : 'Yes'
              }
            ] }
          />
          <h3
            className='mt-5 mb-3'
          >
            Actions
          </h3>
          <Button
            variant={ currentUser.disabled ? 'success' : 'danger' }
            onClick={ () => {
              if (deleteRequestKey)
                actions.clearRequest({
                  key: deleteRequestKey
                })
              displayPrompt(true) 
            } }
          >
            { currentUser.disabled ? 'Activate account' : 'Disable account' }
          </Button>
          {
            deleteRequest &&
            <RequestMessage
              request={ deleteRequest }
              loader
              check
            />
          }
          <ConfirmationModal
            isOpen={ promptIsOpen }
            confirm={ () => {
              displayPrompt(false)
              currentUser && currentUser._id && actions.delete(currentUser._id)
            } }
            cancel={ () => displayPrompt(false) }
            title='Remove user access'
            description={ 
              <p>
                Are you sure you want to { currentUser.disabled ? 're-activate' : 'disable' } the account of&nbsp;
                <strong>{ currentUser.firstName } { currentUser.lastName }</strong> ?
              </p>
            }
          />
        </div>
        }
      </ApiItemWrapper>
    </PageApp>
  )
}

export default UserItem