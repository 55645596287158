import * as React from 'react'
import { ListGroup } from 'react-bootstrap'

interface DescriptionListItem {
  label: string | React.ReactNode
  value: string | React.ReactNode
}

interface DescriptionListProps {
  items: DescriptionListItem[]
}

const DescriptionList: React.FunctionComponent<DescriptionListProps> = ({ items }) => 
  <ListGroup variant='flush'>
    {
      items.map((item, index) =>
        <ListGroup.Item key={ index }>
          <strong>{ item.label }:</strong> { item.value }
        </ListGroup.Item>  
      )
    }
  </ListGroup>

export default DescriptionList