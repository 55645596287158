import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Page } from '_stores/pages/types'

interface PageEditToolsBarProps {
  page: Page
  filterLocale(locale: string): void
  filteredLocales: string[]
  sessionToken?: string
}

const PageEditToolsBar: React.FunctionComponent<PageEditToolsBarProps> = ({
  page,
  filterLocale,
  filteredLocales,
  sessionToken
}) => (
  <>
    {
      page.settings.previewUrl &&
      <Dropdown>
        <Dropdown.Toggle className='ml-2'>
            Preview
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            page.settings.locales.map(locale => 
              <Dropdown.Item
                key={ locale }
                href={ `${page.settings.previewUrl}?page=${page._id}&lang=${locale}` }
                target='_blank'
              >
                { locale }
              </Dropdown.Item>
            )
          }
          <Dropdown.Item
            href={ `${page.settings.previewUrl}?mode=template&page=${page._id}&lang=${page.settings.locales[0]}` }
            target='_blank'
          >
              Template
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    }
    <Dropdown>
      <Dropdown.Toggle className='ml-2'>
          Locales
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          page && page.settings.locales.map(locale => 
            <Dropdown.Item
              key={ locale }
              onClick={ () => filterLocale(locale) }
            >
              {
                (!filteredLocales[0] || filteredLocales.includes(locale)) &&
                <FontAwesomeIcon icon={ faCheck } className='mr-1'/>
              }
              { locale }
            </Dropdown.Item>
          )
        }
      </Dropdown.Menu>
    </Dropdown>
  </>
)
export default PageEditToolsBar