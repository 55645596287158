import { faPager, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { SubMenu } from 'react-pro-sidebar'
import PageForm from '_containers/pages/form'
import { usePages } from '_stores/pages/store'
import SidebarLink from './link'

interface SideBarPagesProps {
  setToggled(toggled: boolean): void
}

const SideBarPages: React.FunctionComponent<SideBarPagesProps> = ({
  setToggled
}) => {
  const [pagesState, pagesActions] = usePages()
  const [modalIsVisible, setModalVisible] = React.useState(false)
  return (
    <>
      <Modal 
        show={ modalIsVisible }
        onHide={ () => setModalVisible(false) }
      >
        <Modal.Body>
          <PageForm
            onSubmit={ (values) => {
              pagesActions.create(values)
              setModalVisible(false)
            } }
          />
        </Modal.Body>
      </Modal>
      <SubMenu
        title={ 'Pages' }
        icon={ <FontAwesomeIcon icon={ faPager } /> }
      >
        {
          pagesState.all.map(page => 
            <SidebarLink
              key={ page._id }
              to={ `/pages/${page._id}` }
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faPager } /> }
              label={ <span>{ page.name }</span> }
              onClick={ () => pagesActions.setCurrent(page) }
            />
          )
        }
        <SidebarLink
          setToggled={ setToggled }
          icon={ <FontAwesomeIcon icon={ faPlusCircle } /> }
          label={ <span>New page</span> }
          onClick={ () => {
            setModalVisible(true)
          } }
        />
      </SubMenu>
    </>
  )
}

export default SideBarPages