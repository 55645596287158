import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { ApiKey, ApiKeyPayload } from './types'
import { ClearRequestPayload, addOrReplaceItemInListById } from '_core/store'

export interface ApiKeysState extends ApiStoreState {
  all: ApiKey[]
  currentApiKeyId?: string
}
export type ApiKeysApi = StoreActionApi<ApiKeysState>

const initialState: ApiKeysState = {
  all: [],
  currentApiKeyId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ setState, getState }: ApiKeysApi) => {
    const res = await callApiFromStore({
      url: '/api-keys',
    }, setState, getState)
    if (res && res.result)
      setState({ all: res.result })
  },
  get: (_id: string) => async ({ setState, getState }: ApiKeysApi) => {
    const res = await callApiFromStore({
      url: `/api-keys/${_id}`
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result),
      currentApiKeyId: res.result._id
    })
  },
  create: (data: ApiKeyPayload) => async ({ setState, getState }: ApiKeysApi) => {
    const res = await callApiFromStore({
      url: '/api-keys',
      method: 'post',
      data
    }, setState, getState)
    setState({
      currentApiKeyId: res.result._id,
      all: [...getState().all, res.result]
    })
  },
  update: (data: ApiKeyPayload) => async ({ setState, getState }: ApiKeysApi) => {
    const res = await callApiFromStore({
      url: `/api-keys/${data._id}`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  delete: (_id: string) => async ({ setState, getState }: ApiKeysApi) => {
    const res = await callApiFromStore({
      url: `/api-keys/${_id}`,
      method: 'delete'
    }, setState, getState)
    setState({ currentApiKeyId: res.result._id })
  },
  setCurrent: (apiKey?: ApiKey) => ({ setState }: ApiKeysApi) => {
    setState({ currentApiKeyId: apiKey && apiKey._id })
  },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: ApiKeysApi) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: ApiKeysApi) => {
    setState({ requests: initialState.requests })
  },
}

export type ApiKeysActions = typeof actions

const Store = createStore<ApiKeysState, ApiKeysActions>({
  name: 'apiKeys',
  initialState,
  actions
})

export const ApiKeysSubscriber = createSubscriber(Store)
export const useApiKeys = createHook(Store)
export const useCurrentApiKey = createHook(Store, {
  selector: (state: ApiKeysState) => state.currentApiKeyId &&
    state.all.find(apiKey => apiKey._id === state.currentApiKeyId)
})
export const ApiKeysContainer = createContainer(Store)

export default Store