import * as React from 'react'
import Title from '_components/title'
import { useCurrentResource } from '_stores/resources/store'
import ResourceForm from './form'
import { Resource } from '_stores/resources/types'
import { useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'

export interface ResourceItemProps {
  resource: Resource
}

const ResourceItem: React.FunctionComponent = () => {
  const [currentResource, actions] = useCurrentResource()
  const history = useHistory()

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentResource }
        actions={ actions }
      >
        <Title
          label={ currentResource ? currentResource.name : 'New resource' }
          button={ {
            label: 'Back to list',
            onClick: () => history.push('/resources')
          } }
        />
        <ResourceForm 
          onSubmit={ currentResource ? actions.update : actions.create }
          resource={ currentResource }
        />
      </ApiItemWrapper>
    </PageApp>
  )
}

export default ResourceItem