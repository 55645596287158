import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { User, PasswordRecoveryPayload, ResetPasswordPayload, InvitePayload, CreatePayload, CompleteProfilePayload, UpdateUserPayload } from './types'
import { ClearRequestPayload, addOrReplaceItemInListById } from '_core/store'

export interface UsersState extends ApiStoreState {
  all: User[]
  currentUserId?: string
}
export type UsersApi = StoreActionApi<UsersState>

const initialState: UsersState = {
  all: [],
  currentUserId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: '/users',
    }, setState, getState)
    if (res && res.result)
      setState({ all: res.result })
  },
  get: (_id: string) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: `/users/${_id}`
    }, setState, getState)
    setState({
      currentUserId: res.result._id,
      all: addOrReplaceItemInListById(getState().all, res.result),
    })
  },
  create: (data: CreatePayload) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: '/users',
      method: 'post',
      data
    }, setState, getState)
    setState({ currentUserId: res.result._id, all: [...getState().all, res.result] })
  },
  update: (data: UpdateUserPayload) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: `/users/${data.id}`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result),
    })
  },
  delete: (_id: string) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: `/users/${_id}`,
      method: 'delete'
    }, setState, getState)
    setState({ currentUserId: res.result._id })
  },
  setCurrent: (user?: User) => async ({ setState }: UsersApi) => {
    setState({ currentUserId: user && user._id })
  },
  invite: (data: InvitePayload) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: '/users/invite',
      method: 'post',
      data
    }, setState, getState)
    if (res && res.result) {
      setState({
        all: [...getState().all, res.result]
      })
    }
  },
  passwordRecovery: (data: PasswordRecoveryPayload) => async ({ setState, getState }: UsersApi) => {
    await callApiFromStore({
      url: '/users/password-recovery',
      method: 'post',
      data
    }, setState, getState)
  },
  resetPassword: (data: ResetPasswordPayload) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: '/users/reset-password',
      method: 'post',
      data
    }, setState, getState)
    if (res && res.result && res.result.token)
      location.replace(`/is-auth?token=${res.result.token}`)
  },
  completeProfile: (data: CompleteProfilePayload) => async ({ setState, getState }: UsersApi) => {
    const res = await callApiFromStore({
      url: '/users/complete-profile',
      method: 'post',
      data
    }, setState, getState)
    if (res && res.result && res.result.token)
      location.replace(`/is-auth?token=${res.result.token}`)
  },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: UsersApi) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: UsersApi) => {
    setState({ requests: initialState.requests })
  },
}

export type UsersActions = typeof actions

const Store = createStore<UsersState, UsersActions>({
  name: 'users',
  initialState,
  actions
})

export const UsersSubscriber = createSubscriber(Store)
export const useUsers = createHook(Store)
export const useCurrentUser = createHook(Store, {
  selector: (state: UsersState) => state.currentUserId &&
    state.all.find(user => user._id === state.currentUserId)
})
export const UsersContainer = createContainer(Store)

export default Store