import * as React from 'react'
import Title from '_components/title'
import { useCurrentPage } from '_stores/pages/store'
import { Page } from '_stores/pages/types'
import { Link, Route, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { Nav, Row } from 'react-bootstrap'
import Switch from 'react-bootstrap/esm/Switch'
import PageFields from './fields'
import classNames from 'classnames'
import PageEdit from './edit'
import PageSettings from './settings'

export interface PageItemProps {
  page: Page
}

const PageItem: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()
  const { pathname } = useLocation()
  let activeNav = 'edit'
  if (pathname.match('/fields'))
    activeNav = 'fields'
  if (pathname.match('/settings'))
    activeNav = 'settings'

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentPage }
        actions={ actions }
      >
        <Title
          label={ currentPage ? currentPage.name : 'New page' }
        />
        <Nav fill variant='tabs'>
          <Nav.Item>
            {
              currentPage && 
              <Nav.Link
                as={ Link }
                to={ `/pages/${currentPage._id}` }
                className={ classNames({
                  active: activeNav === 'edit'
                }) }
              >
                Edit
              </Nav.Link>
            }
          </Nav.Item>
          <Nav.Item>
            {
              currentPage && 
              <Nav.Link
                as={ Link }
                to={ `/pages/${currentPage._id}/fields` }
                className={ classNames({
                  active: activeNav === 'fields'
                }) }
              >
                Fields
              </Nav.Link>
            }
          </Nav.Item>
          <Nav.Item>
            {
              currentPage && 
                <Nav.Link
                  as={ Link }
                  to={ `/pages/${currentPage._id}/settings` }
                  className={ classNames({
                    active: activeNav === 'settings'
                  }) }
                >
                  Settings
                </Nav.Link>
            }
          </Nav.Item>
        </Nav>
        <div className='mt-3'>
          <Switch>
            <Route
              path='/pages/:id/fields'
              component={ PageFields }
              exact
            />
            <Route
              path='/pages/:id/settings'
              component={ PageSettings }
              exact
            />
            <Route path='/pages/:id' exact component={ PageEdit }/>
          </Switch>
        </div>
      </ApiItemWrapper>
    </PageApp>
  )
}

export default PageItem