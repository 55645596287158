import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import PasswordRecoveryForm from './form'
import { Link } from 'react-router-dom'
import { useUsers } from '_stores/users/store'

const PasswordRecovery: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  return (
    <PageCenteredForm 
      title={ 'Password recovery' }
      titleDescription={ 'Receive an email with a link to change your password' }
      icon={ <FontAwesomeIcon icon={ faLock } size='2x' /> }
      footer={ <Link to='/login'>Cancel</Link> }
    >
      <PasswordRecoveryForm />
    </PageCenteredForm>
  )
}

export default PasswordRecovery