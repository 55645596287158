import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'

export interface InputFieldProps extends BaseFormFieldProps {
  type?: 'text' | 'email' | 'password' | 'phone'
  disabled?: boolean
  field?: any
  autoComplete?: string
}

export const InputField: React.FunctionComponent<InputFieldProps> = (props) => (
  <Field { ...props }>
    <Form.Control
      type={ props.type }
      placeholder={ props.placeholder }
      isValid={ !Boolean(props.error) }
      isInvalid={ Boolean(props.error) }
      onChange={ (e) => props.onChange && props.onChange(e.target.value) }
      onBlur={ (e: any) => props.onBlur && props.onBlur(e.target.value) }
      onKeyPress={ (e: any) => props.onEnterPress && e.key === 'Enter' && props.onEnterPress(e.target.value) }
      defaultValue={ props.defaultValue }
      name={ props.field ? props.field.name : props.name }
      disabled={ props.disabled }
      autoComplete={ props.autoComplete }
      autoFocus={ props.autoFocus }
    />
  </Field>
)

export default InputField