import * as React from 'react'
import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react'

interface CloudinaryImageProps {
  publicId: string
  transformations?: any
}

const CloudinaryImage: React.FunctionComponent<CloudinaryImageProps> = ({
  publicId,
  transformations
}) => 
  <CloudinaryContext cloudName='dkgryqpim'>
    <Image publicId={ publicId }>
      {
        transformations &&
          <Transformation { ...transformations } />
      }
    </Image>
  </CloudinaryContext>


export default CloudinaryImage