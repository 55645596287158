import * as React from 'react'
import { useAppUser, useSession } from '_stores/session/store'
import PageApp from '_containers/app-pages/page-app'
import DescriptionList from '_components/description-list'
import AutoSaveField from '_components/form/auto-save-field'
import UserPasswordForm from './password-form'
import { Row, Col, Button } from 'react-bootstrap'

const UserProfile:React.FunctionComponent = () => {
  const [currentUser] = useAppUser() 
  const [state, actions] = useSession()

  const infosFields = React.useMemo(() => [
    {
      label: 'First name',
      value: <AutoSaveField 
        field={ {
          name: 'firstName',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          firstName: value
        }) }
        initialValue={ currentUser.firstName }
      />
    },
    {
      label: 'Last name',
      value: <AutoSaveField 
        field={ {
          name: 'lastName',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          lastName: value
        }) }
        initialValue={ currentUser.lastName }
      />
    },
    {
      label: 'Email',
      value: <AutoSaveField 
        field={ {
          name: 'email',
          type: 'text'
        } }
        onSubmit={ (value) => actions.updateProfile({
          email: value
        }) }
        initialValue={ currentUser.email }
      />
    }
  ], [currentUser])

  return (
    <PageApp>
      <h2>Profile informations</h2>
      <hr />
      <DescriptionList
        items={ infosFields }
      />
      <h2 className='mt-4'>Password</h2>
      <hr />
      <Row>
        <Col md={ 6 }>
          <UserPasswordForm />
        </Col>
      </Row>
    </PageApp>
  )
}

export default UserProfile