import * as React from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import { Table as BsTable } from 'react-bootstrap'

export interface TableProps {
  columns: Column[]
  data: any[]
  onRowClick?(row): void
}

const Table = ({ columns, data, onRowClick }: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  return (
    <>
      <BsTable { ...getTableProps() } striped bordered>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr { ...headerGroup.getHeaderGroupProps() }>
              {headerGroup.headers.map((column: any) => (
                <th { ...column.getHeaderProps(column.getSortByToggleProps()) }>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody { ...getTableBodyProps() }>
          {rows.map(
            (row, i) => {
              prepareRow(row)
              const props = row.getRowProps()
              return (
                <tr { ...props } onClick={ () => onRowClick && onRowClick(row) }>
                  { row.cells.map(cell => (
                    <td { ...cell.getCellProps() }>{cell.render('Cell')}</td>
                  )) }
                </tr>
              ) }
          )}
        </tbody>
      </BsTable>
    </>
  )
}

export default Table