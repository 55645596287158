import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { Page, PageField, UpdatePageContextPayload } from './types'
import { ClearRequestPayload, addOrReplaceItemInListById } from '_core/store'
import history from '_core/history'

export interface PagesState extends ApiStoreState {
  all: Page[]
  currentPageId?: string
}
export type PagesApi = StoreActionApi<PagesState>

const initialState: PagesState = {
  all: [],
  currentPageId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: '/pages',
    }, setState, getState)
    if (res && res.result)
      setState({ all: res.result })
  },
  get: (_id: string) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${_id}`
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result),
      currentPageId: res.result._id
    })
  },
  create: (data: Page) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: '/pages',
      method: 'post',
      data
    }, setState, getState)
    setState({
      currentPageId: res.result._id,
      all: [...getState().all, res.result]
    })
    history.push(`/pages/${ res.result._id}`)
  },
  update: (data: Partial<Page>) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${data._id}`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  delete: (_id: string) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${_id}`,
      method: 'delete'
    }, setState, getState)
    setState({ currentPageId: res.result._id })
  },
  createField: (pageId: string, field: PageField, parentId?: string) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${pageId}/fields`,
      method: 'post',
      data: {
        field,
        parentId
      }
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  updateField: (pageId: string, field: Partial<PageField>) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${pageId}/fields/${field._id}`,
      method: 'put',
      data: {
        field
      }
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  removeField: (pageId: string, fieldId: string) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${pageId}/fields/${fieldId}`,
      method: 'delete'
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  updateContext: (pageId: string, data: UpdatePageContextPayload) => async ({ setState, getState }: PagesApi) => {
    const res = await callApiFromStore({
      url: `/pages/${pageId}/context`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  setCurrent: (page?: Page) => ({ setState }: PagesApi) => {
    setState({ currentPageId: page && page._id })
  },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: PagesApi) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: PagesApi) => {
    setState({ requests: initialState.requests })
  },
}

export type PagesActions = typeof actions

const Store = createStore<PagesState, PagesActions>({
  name: 'pages',
  initialState,
  actions
})

export const PagesSubscriber = createSubscriber(Store)
export const usePages = createHook(Store)
export const useCurrentPage = createHook<PagesState, PagesActions, Page | undefined | ''>(Store, {
  selector: (state: PagesState) => state.currentPageId &&
    state.all.find(page => page._id === state.currentPageId)
})
export const PagesContainer = createContainer(Store)

export default Store