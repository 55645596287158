import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Row } from 'react-bootstrap'
import CarouselModal from '_components/carousel/modal'
import CloudinaryImage from '_components/cloudinary-image'
import OverlayDropZone from '_components/overlay-dropzone'
import { ApiStoreRequest } from '_core/api'
import { Media } from '_stores/medias/types'
import MediaThumbnail from './thumbnail'

interface MediasProps {
  medias: Media[]
  noCarousel?: boolean
  readOnly?: boolean
  onDrop?(files: any[]): void
  onDropzoneHide?(): void
  request?: ApiStoreRequest
  selectMode?: boolean
  onSelect?(media: Media): void
  selected?: string[]
  acceptedFiles?: string
}

const Medias: React.FunctionComponent<MediasProps> = ({
  medias,
  noCarousel,
  readOnly,
  request,
  onDrop,
  onDropzoneHide,
  selectMode,
  onSelect,
  selected,
  acceptedFiles
}) => {
  const [
    currentCarouselImageIndex,
    setCurrentCarouselImageIndex
  ] = React.useState(-1)

  const [dropzoneIsVisible, setDropzoneVisible] = React.useState(false)

  React.useEffect(() => {
    if (request && request.status === 'success')
      setDropzoneVisible(false)
  }, [request])

  return(
    <>
      {
        !noCarousel &&
        <CarouselModal
          medias={ medias }
          onSelect={ setCurrentCarouselImageIndex }
          activeIndex={ currentCarouselImageIndex }
        />
      }
      {
        !readOnly &&
          <OverlayDropZone 
            onDrop={ (files: any) => onDrop && onDrop(files) }
            forceShow={ dropzoneIsVisible }
            onHide={ () => {
              setDropzoneVisible(false)
              onDropzoneHide && onDropzoneHide()
            } }
            request={ request }
            accept={
              acceptedFiles || 'image/jpeg,image/gif,image/png,application/pdf,image/x-ep'
            }
          />
      }
      <Row className='mt-3 medias'>
        {
          !readOnly &&
            <MediaThumbnail
              onClick={ () => setDropzoneVisible(true) }
              className='upload-invite'
            >
              <div>
                <FontAwesomeIcon icon={ faPlusCircle } size='3x' />
              </div>
              <div>
                Drag some files here
                <br />
                or click to select
              </div>
            </MediaThumbnail>
        }
        {
          medias.map((media, index) => 
            <MediaThumbnail
              key={ media._id }
              onClick={ () => {
                if (selectMode && onSelect && media._id)
                  onSelect(media)
                else
                  setCurrentCarouselImageIndex(index)
              } }
            >
              {
                selectMode &&
                <input
                  type='radio'
                  onChange={ () => media._id && onSelect && onSelect(media) }
                  checked={ Boolean(media._id && selected && selected.includes(media._id)) }
                />
              }
              <CloudinaryImage
                publicId={ media.providerId }
                transformations={ {
                  width: '330',
                  height: '330',
                  crop: 'thumb'
                } }
              />
            </MediaThumbnail>  
          )
        }
      </Row>
    </>
  )
}

export default Medias