import * as React from 'react'
import { useUsers } from '_stores/users/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import InviteUserModal from './invite-modal'

const UsersList: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  const history = useHistory()
  React.useEffect(() => {
    actions.fetch()
  }, [])

  //Invite modal
  const [inviteModalIsOpen, setInviteModalOpen] = React.useState(false)
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any) => 
          <Link to={ `/users/${d._id}` }>
            { d.disabled && <FontAwesomeIcon icon={ faBan } className='mr-1' />}
            { d.firstName } { d.lastName }
          </Link>
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: ' ',
        accessor: (d: any) => 
          d.passwordValidated && <FontAwesomeIcon icon={ faCheckCircle } title='Completed' />
      }
    ],
    []
  )

  return (
    <PageApp>
      <Title
        label='Users'
        button={ {
          label: 'Invite user',
          onClick: () => setInviteModalOpen(true)
        } }
      />
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ state.all }
        />
      </div>
      <InviteUserModal
        isOpen={ inviteModalIsOpen }
        setOpen={ (isOpen: boolean) => setInviteModalOpen(isOpen) }
      />
    </PageApp>
  )
}

export default UsersList