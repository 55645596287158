import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Table from '_components/table'
import { useCurrentPage } from '_stores/pages/store'
import PageFieldForm from './form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faClipboardCheck, faPlus, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { PageField } from '_stores/pages/types'
import _ from 'lodash'
let lastCopy = 0

const PageFields: React.FunctionComponent = () => {
  const [currentPage, actions] = useCurrentPage()
  const [copiedToClipboard, setCopiedToClipboard] = React.useState('')
  const [currentParent, setCurrentParrent] = React.useState()
  React.useEffect(() => {
    const now = new Date().getTime()
    lastCopy = now
    setTimeout(() => now === lastCopy && setCopiedToClipboard(''), 2000)
  }, [copiedToClipboard])
  if (!currentPage)
    return <></>

  const [fieldModalIsVisible, displayFieldModal] = React.useState(false)
  const [currentField, setCurrentField] = React.useState()

  const fields = React.useMemo(() => {
    const rows: PageField[] = []

    const buildFieldsRow = (fields: PageField[], level = 0, key = '') => {
      _.sortBy(fields, (i) => i.name.toLowerCase()).forEach(field => {
        field.level = level
        field.key = !key ? field.name : `${key} / ${field.name}`
        rows.push(field)
        if (field.type === 'list' && field.options.fields) {
          buildFieldsRow(field.options.fields, level + 1, field.key)
        }
      })
    }

    buildFieldsRow(currentPage.fields)
    return rows
  }, [currentPage.fields])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any) => 
          <span>
            <a href='#' className='mr-2' style={ { paddingLeft: d.level * 40 } }>
              { d.name }
            </a>
            <CopyToClipboard text={ d.name }>
              <Button
                size='sm'
                variant='link'
                onClick= {
                  (e) => {
                    e.stopPropagation()
                    setCopiedToClipboard(d.name)
                  }
                }
              >
                <FontAwesomeIcon icon={ copiedToClipboard === d.name ? faClipboardCheck : faClipboard }/>
              </Button>
            </CopyToClipboard>
            {
              copiedToClipboard === d.name && <small className='ml-1'>Copied!</small>
            }
            {
              d.type === 'list' &&
              <Button
                size='sm'
                variant='link'
                onClick={ (e) => {
                  e.stopPropagation()
                  setCurrentParrent(d)
                  setCurrentField(undefined)
                  displayFieldModal(true)
                } }
              >
                <FontAwesomeIcon icon={ faPlus } className='mr-1' />
                Add field
              </Button>
            }
          </span>
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
    ],
    [copiedToClipboard]
  )
  
  return (
    <div>
      <Button
        onClick={ () => {
          setCurrentField(undefined)
          setCurrentParrent(undefined)
          displayFieldModal(true)
        } }
      >
        New field
      </Button>
      <Table
        columns={ columns }
        data={ fields }
        onRowClick={ (row) => {
          setCurrentParrent(undefined)
          setCurrentField(row.original)
          displayFieldModal(true)
        } }
      />
      <Modal
        show={ fieldModalIsVisible }
        onHide={ () => displayFieldModal(false) }
        size='lg'
      >
        <Modal.Header>
          <h3 className='mb-0'>
            { currentField ? currentField.key : 'New field' }
            {
              currentParent && <small> > { currentParent.key }{ currentParent && ' / ' }</small>
            }
          </h3>
        </Modal.Header>
        <Modal.Body>
          <PageFieldForm 
            field={ currentField }
            defaultValue={ {
              parentId: currentParent && currentParent._id
            } }
            onRemoved={ () => displayFieldModal(false) }
            onCreated={ () => displayFieldModal(false) }
          />
        </Modal.Body>
      </Modal>
      { /*<PageFieldForm /> */}
    </div>
  )
}
export default PageFields