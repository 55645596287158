import * as React from 'react'
import Title from '_components/title'
import { useCurrentApiKey } from '_stores/api-keys/store'
import ApiKeyForm from './form'
import { ApiKey } from '_stores/api-keys/types'
import { useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'

export interface ApiKeyItemProps {
  apiKey: ApiKey
}

const ApiKeyItem: React.FunctionComponent = () => {
  const [currentApiKey, actions] = useCurrentApiKey()
  const history = useHistory()

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentApiKey }
        actions={ actions }
      >
        <Title
          label={ currentApiKey ? currentApiKey.name : 'New API Key' }
          button={ {
            label: 'Back to list',
            onClick: () => history.push('/api-keys')
          } }
        />
        <ApiKeyForm 
          onSubmit={ currentApiKey ? actions.update : actions.create }
          apiKey={ currentApiKey }
        />
      </ApiItemWrapper>
    </PageApp>
  )
}

export default ApiKeyItem