import axios, { AxiosInstance, Method } from 'axios'
import { SetState, GetState } from 'react-sweet-state'
import { getStoreState } from './local-storage'

const api = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export interface ApiCallConfig {
  method?: Method
  url: string
  params?: any
  data?: any
  headers?: any
  progress?: boolean
  onUploadProgress?: any
}

export interface ApiStoreRequest {
  lastCallDate: number
  status: 'inProgress' | 'success' | 'error'
  loaded?: string
  message?: string
  response?: {
    message?: string
    [key: string]: any
  }
  error?: {
    message?: string
    code: number
    [key: string]: any
  }
  [key: string]: any
}

export interface ApiStoreState {
  requests: {
    [key: string]: ApiStoreRequest
  }
}

const lastCalls = {}

export const callApiFromStore = async (config: ApiCallConfig, setState: SetState<ApiStoreState>, getState: GetState<ApiStoreState>): Promise<any> => {
  const callDate = new Date().getTime()
  if (!config.method)
    config.method = 'get'
  if (config.method === 'get' && config.data)
    config.params = config.data

  if (config.progress)
    config.onUploadProgress = (progressEvent: any) => setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'inProgress',
          loaded: `${parseInt(progressEvent.loaded / 1000)}KB / ${parseInt(progressEvent.total / 1000)}KB (${parseInt((progressEvent.loaded / progressEvent.total) * 100)}%)`
        }
      }
    })

  const requestKey = `${config.method.toLowerCase()} ${config.url}`

  
  setState({
    requests: {
      ...getState().requests,
      [requestKey]: {
        lastCallDate: callDate,
        status: 'inProgress'
      }
    }
  })
  try {
    let headers: { authorization?: string } = {}

    //Set authorization token in headers
    const sessionState = getStoreState('session')
    if (sessionState && sessionState.token)
      headers.authorization = sessionState.token

    if (config.headers)
      headers = { ...headers, ...config.headers }

    const res = await api.request({
      ...config,
      headers
    })
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'success',
          response: res.data,
          message: res.data.message
        }
      }
    })
    /*const now = new Date().getTime()
    lastCalls[requestKey] = now
    setTimeout(() => {
      setState({
        requests: {
          ...getState().requests,
          [requestKey]: undefined
        }
      })
    }, 3000)*/
    return res.data
  } catch (err) {
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'error',
          error: err.response && err.response.data,
          message: err.response && err.response.data && err.response.data.message
        }
      }
    })
  }
}

export default api as AxiosInstance