import Resources from './containers/resources/list'
import Resource from './containers/resources/item'
import CreateUser from './containers/users/create'
import Users from './containers/users/list'
import User from './containers/users/item'
import Login from '_containers/login'
import Logout from '_containers/logout'
import PasswordRecovery from '_containers/password-recovery'
import PasswordReset from '_containers/password-reset'
import IsAuth from './containers/is-auth'
import UserProfile from '_containers/user-profile'
import PageItem from '_containers/pages/item'
import PagesList from '_containers/pages/list'
import MediasList from '_containers/medias/list'
import MediaItem from '_containers/medias/item'
import ApiKeysList from '_containers/api-keys/list'
import ApiKeyItem from '_containers/api-keys/item'

interface Route {
  path: string
  component: any
  logged?: boolean | 'common'
}

const routes: Route[] = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout,
    logged: true
  },
  {
    path: '/password-recovery',
    component: PasswordRecovery
  },
  {
    path: '/reset-password',
    component: PasswordReset
  },
  {
    path: '/resources',
    component: Resources,
    logged: true
  },
  {
    path: '/resources/:id',
    component: Resource,
    logged: true
  },
  {
    path: '/api-keys',
    component: ApiKeysList,
    logged: true
  },
  {
    path: '/api-keys/:id',
    component: ApiKeyItem,
    logged: true
  },
  {
    path: '/pages',
    component: PagesList,
    logged: true
  },
  {
    path: '/pages/:id/settings',
    component: PageItem,
    logged: true
  },
  {
    path: '/pages/:id/fields',
    component: PageItem,
    logged: true
  },
  {
    path: '/pages/:id',
    component: PageItem,
    logged: true
  },
  {
    path: '/medias',
    component: MediasList,
    logged: true
  },
  {
    path: '/medias/:id',
    component: MediaItem,
    logged: true
  },
  {
    path: '/users',
    component: Users,
    logged: true
  },
  {
    path: '/users/create',
    component: CreateUser
  },
  {
    path: '/users/:id',
    component: User,
    logged: true
  },
  {
    path: '/is-auth',
    component: IsAuth,
    logged: 'common'
  },
  {
    path: '/me',
    component: UserProfile,
    logged: true
  }
]

export const getRoute = (path: string): Route | undefined =>
  routes.find(r => r.path === path)

export default routes