import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { callApiFromStore, ApiStoreState } from '_core/api'
import { Resource } from './types'
import { ClearRequestPayload, addOrReplaceItemInListById } from '_core/store'

export interface ResourcesState extends ApiStoreState {
  all: Resource[]
  currentResourceId?: string
}
export type ResourcesApi = StoreActionApi<ResourcesState>

const initialState: ResourcesState = {
  all: [],
  currentResourceId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ setState, getState }: ResourcesApi) => {
    const res = await callApiFromStore({
      url: '/resources',
    }, setState, getState)
    if (res && res.result)
      setState({ all: res.result })
  },
  get: (_id: string) => async ({ setState, getState }: ResourcesApi) => {
    const res = await callApiFromStore({
      url: `/resources/${_id}`
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result),
      currentResourceId: res.result._id
    })
  },
  create: (data: Resource) => async ({ setState, getState }: ResourcesApi) => {
    const res = await callApiFromStore({
      url: '/resources',
      method: 'post',
      data
    }, setState, getState)
    setState({
      currentResourceId: res.result._id,
      all: [...getState().all, res.result]
    })
  },
  update: (data: Resource) => async ({ setState, getState }: ResourcesApi) => {
    const res = await callApiFromStore({
      url: `/resources/${data._id}`,
      method: 'put',
      data
    }, setState, getState)
    setState({ 
      all: addOrReplaceItemInListById(getState().all, res.result)
    })
  },
  delete: (_id: string) => async ({ setState, getState }: ResourcesApi) => {
    const res = await callApiFromStore({
      url: `/resources/${_id}`,
      method: 'delete'
    }, setState, getState)
    setState({ currentResourceId: res.result._id })
  },
  setCurrent: (resource?: Resource) => ({ setState }: ResourcesApi) => {
    setState({ currentResourceId: resource && resource._id })
  },
  clearRequest: (data: ClearRequestPayload) => async ({ setState, getState }: ResourcesApi) => {
    const requests = getState().requests
    delete requests[data.key]
    setState({ requests })
  },
  clearRequests: () => ({ setState }: ResourcesApi) => {
    setState({ requests: initialState.requests })
  },
}

export type ResourcesActions = typeof actions

const Store = createStore<ResourcesState, ResourcesActions>({
  name: 'resources',
  initialState,
  actions
})

export const ResourcesSubscriber = createSubscriber(Store)
export const useResources = createHook(Store)
export const useCurrentResource = createHook(Store, {
  selector: (state: ResourcesState) => state.currentResourceId &&
    state.all.find(resource => resource._id === state.currentResourceId)
})
export const ResourcesContainer = createContainer(Store)

export default Store