import * as React from 'react'
import { usePages } from '_stores/pages/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'

const PagesList: React.SFC = () => {
  const [state, actions] = usePages()
  const history = useHistory()
  React.useEffect(() => {
    actions.fetch()
  }, [])
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (d: any) => <Link to={ `/pages/${d._id}` }>{ d.name }</Link>
      },
      {
        Header: 'Date',
        accessor: 'dates.created',
      },
    ],
    []
  )
  

  return (
    <PageApp>
      <Title
        label='Pages'
        button={ {
          label: 'New page',
          onClick: () => {
            actions.setCurrent()
            history.push('/pages/new')
          }
        } }
      />
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ state.all }
        />
      </div>
    </PageApp>
  )
}

export default PagesList