import { FormFieldType } from './types'
import InputField from './fields/input'
import PasswordField from './fields/password'
import CheckboxField from './fields/check'
import SelectField from './fields/select'
import FroalaEditor from './fields/froala'
import FilesField from './fields/files'
import MediasField from './fields/medias'
import TextsField from './fields/texts'
import Quill from './fields/react-quill'

export const getComponent = (inputType: FormFieldType): React.ReactNode => {
  switch (inputType) {
    case 'password':
      return PasswordField
    case 'checkbox':
      return CheckboxField
    case 'select':
      return SelectField
    case 'wysiwyg':
      return Quill
    case 'files':
      return FilesField
    case 'medias':
      return MediasField
    case 'texts':
      return TextsField
    default:
      return InputField
  }
}